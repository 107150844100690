import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/Seo'

import Cover from '../components/Cover'
import VideoBox from '../components/VideoBox'
import CTA from '../components/Cta'

import coverImage from '../images/criptovalute-e-monete-virtuali.jpg'

import posterDiversificare from '../images/poster-monete-virtuali.png'

const Impulsivita = () => (
  <Layout>
    <SEO title="Criptovalute e monete virtuali" />
    <Cover
      title="Criptovalute e monete virtuali"
      color="white"
      bg={coverImage}
      icon=""
      link="#video"
    >
      <p>
        Bitcoin, blockchain e simili forse saranno la moneta del futuro. Quel
        che è sicuro, al momento attuale, è che sono alcuni degli investimenti
        più rischiosi – e senza alcuna possibilità di rivalsa – esistenti sul
        mercato finanziario. Anche con la guida giusta, il rischio di perdere il
        capitale con le monete virtuali rimane altissimo.
      </p>
    </Cover>
    <VideoBox ytID="oWmcL3EVWRk" poster={posterDiversificare} />
    <CTA />
  </Layout>
)

export default Impulsivita
